<template>
	<v-container class="pa-0" fluid>
		<!-- TOOLBAR -->
		<v-row
			no-gutters
			:style="stickyTop"
			align="center"
			class="white"
			justify="space-between"
		>
			<v-col cols="12">
				<v-container class="pa-0">
					<v-toolbar flat dense>
						<v-toolbar-title>Onboarding</v-toolbar-title>
						<template v-slot:extension> </template>
					</v-toolbar>
				</v-container>
			</v-col>

			<v-col cols="12">
				<v-progress-linear
					v-if="loading"
					active
					indeterminate
					rounded
				></v-progress-linear>
				<v-divider v-else class="accent" style="padding-top: 2px" />
			</v-col>
		</v-row>
		<!-- DATATABLE -->
		<v-container>
			<v-row>
				<v-col cols="12">
					<v-data-table
						v-if="itemList.length"
						:headers="headers"
						:items="itemList"
						:loading="loading"
						disable-pagination
						hide-default-footer
						no-data-text=""
					>
						<template v-slot:[`item.actions`]="{ item }">
							<v-btn
								icon
								@click="getCatalogItemDetail(item.item_id)"
							>
								<v-icon>mdi-chevron-right</v-icon>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>
<script>
	import caas from "@/services/caas";
	import { mapGetters } from "vuex";
	export default {
		name: "PIM",
		data() {
			return {
				headers: [
					{ text: "ID", value: "item_id" },
					{ text: "Item", value: "item_name" },
					{ text: "Status", value: "wf_status" },
					{ text: "", value: "actions" },
				],
				loading: false,
				nbrOfRows: null,
			};
		},
		computed: {
			...mapGetters({
				itemList: "catalogItem/itemList",
			}),

			stickyTop() {
				return {
					position: "sticky",
					top: this.$vuetify.application.top + "px",
					zIndex: 1,
				};
			},
		},
		methods: {
			getItemList() {
				const sdoName = "web/objects/dECatItem.r";
				const params = {
					action: "read",
					nbrOfRows: 9999,
					setOpenQuery:
						"for each ecat_item where wf_process='ONBOARDING' no-lock indexed-reposition",
				};
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.commit(
								"catalogItem/SET_ITEM_LIST",
								response.data.success.data.RowObject
							);
							this.nbrOfRows = response.data.success.data.nbrOfRows;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			getCatalogItemDetail(_itemId) {
				this.$router.push({
					name: "CatalogItem",
					params: { id: _itemId },
				});
			},
		},
		mounted() {
			this.getItemList();
		},
	};
</script>